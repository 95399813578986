import React from 'react'
import { Box, Text, Flex, Grid, Heading, Styled } from 'theme-ui'
import Layout from "../components/layout"
import Image from "../components/Image"
import Link, {Anchor} from "../components/Link"
import Toggle from "../components/Toggle"
import Slideshow from "../components/Slideshow"
import SEO from "../components/seo"
import {Parallax} from "react-scroll-parallax"

export default () => 
  <Layout>
    <SEO keywords={['design', 'product designer', 'interface designer', 'case study', 'ux case study', 'b2b product design']} title='Specle Case Study' description="Redesigning a 12 years old complex b2b service for delivering print, digital, and out-of-home advertisements" project="specle" />

    <Heading as="h1" variant="text.h1" sx={{mb: 6}}>Specle</Heading>
    <Box sx={{maxWidth: '50rem', mb: 8}}>
      <Text variant="h4">Redesigning a 12 years old complex b2b service for delivering print, digital, and out-of-home advertisements.</Text>

      <Flex sx={{ mt: [4, 5], mx: [-2, -3],
            justifyContent: 'space-between',
            flexDirection: ['column', 'row'],'a': { mb: [2, 0], }}}
      >
        <Link href="https://specle.net" sx={{variant: "links.button", flexBasis: [null, 'calc(100%/3 - 4px)'], flexGrow: [0, null, 1, 0]}} >
          <Anchor sx={{my: -1}} />
          <span>Website</span>
        </Link>
      </Flex>
    </Box>

    <Box variant='boxes.gaplessImage' sx={{mb: 7}}>
      <Parallax y={[0, -30]} styleOuter={{maxHeight: "52.612vw"}}>
        <Image src="specle/parallax.png" alt='Hero image' />
      </Parallax>
    </Box>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="①" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        An Overview, Goals and Challenges
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            Specle is a print and out-of-home ad delivery platform that allows you to prepare and deliver your ad directly to the publisher. With the up-to-date and verified ad specs database, and a fully-fledged preflighting service built in with checks and fixups, it ensures that each sent ad copy meets the publisher’s requirements.
          </Text>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            When I joined the company in mid-2017, I realised that the development team had been executing every single new feature the customers wanted. That has led to an unstructured IA and poor, cluttered interfaces, including the popular and crucial task flows.
          </Text>
        </Box>
        <Box sx={{mt: 5, mb: 8, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Text variant="h4" as="h3" sx={{mb: 4}}>
            Goals and Challenges
          </Text>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", rowGap: 3, columnGap: 8}}>
            <Box as="ul" sx={{variant: "styles.ul", my: 0}}>
              <Styled.li>Update design of the existing services of the Specle family by introducing a new layout and UI components.</Styled.li>
              <Styled.li>Improve the existing user flows iterating with small increments. All while being respectful to our time-pressed customers who might get overwhelmed by a sudden interface change.</Styled.li>
              <Styled.li>Build new automation tools for prepress technicians and customer support team to help them with routine tasks and communication.</Styled.li>
              <Styled.li>Integrate PADN, a third-party service to automate copy chasing. Their API was rather brittle and didn’t handle many edge cases, so we had to think through to make the experience OK.</Styled.li>
            </Box>
            <Box as="ul" sx={{variant: "styles.ul", my: 0}}>
              <Styled.li>Move away from the Foundation/jQuery frameworks in 9-years-old legacy application to a new stack.</Styled.li>
              <Styled.li>We lacked an analyst. To prove design decisions and verify ideas with data, I dug into Google Analytics and even query the database.
</Styled.li>
              <Styled.li>To speed up the development process, I worked directly with our codebase. For the most part the changes I made were in the front-end side of the codebase but some design changes required me to tinker with the Rails-powered backend. PSA: not all of my PRs have passed code review.</Styled.li>
            </Box>
          </Grid>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="②" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Design Guidelines
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box>
              <Text as="p">
                Caused by the lack of design guidelines since 2010, Specle had an inconsistency of styles, elements, and entire flows across the product. That led to a disjointed user experience which had to be fixed.
              </Text>
              <Text as="p">
              I reviewed every pixel of Specle to create a structure of atoms and molecules for the guidelines. From the colour palette and typography to buttons, inputs, and drop-downs — I came up with a Figma library of interface elements.
              </Text>
            </Box>
            <Box>
              <Text as="p">
              Soon we’ve realised that we should turn all the components into code. Yet, we could not afford to spend time of our small development team on this project. To help the situation I’ve taken the responsibility of developing a CSS framework accompanied by a set of HTML snippets, both documented in the guidelines I’ve also provided.
              </Text>
            </Box>
          </Grid>
        </Box>
        <Box sx={{bg: "subtleBg", borderRadius: 3, py: 7, mt: 7, mb: 8}}>
          <Image src="specle/ui.png" alt='Guidelines' height="40vw" sx={{mx: "auto"}}/>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4, mx: [-4, -6, -8]}}>
      <Toggle.Heading index="③" sx={{variant: 'text.h2', mx: [4,6,8], cursor: 'pointer', '&:hover': {opacity: 0.3}}}>New Layout and Flows</Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, mx: [4,6,8], mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
          When I’ve joined, Specle was already a well established business, so I had plenty of analytics data and stakeholders’ input to base my redesign on. I also talked to the customer support team and joined their collaborative inbox to gather all the support requests. That allowed me to get many insights: what are the common problems customers face, what language do they use, what do they want and in what situations do they use Specle…
          </Text>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
          That helped me to come up with job stories which I used to create early prototypes to be tested on the customers.
          </Text>
        </Box>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>
            Creating Ad Deliveries
          </Text>
          <Text>
          Creating a new ad delivery is the most used flow on Specle. Every day our customers send hundreds of ad materials to thousands of publications across the globe. It’s also the most complicated one — there can be many different combinations of elements depending on the user type and required fields set for the publication/specification by the publisher.
          </Text>
        </Box>
        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="We rearranged the layout of the page so that the parts that may vary from delivery to delivery have their own, visible spots.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/new-delivery.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="For example, while on the previous screenshot the spec has a fixed size, here it’s a column size which means the customer can set the ad dimensions. Below the spec, you can see insertion information. When the customer enters the booking number in here, the rest of the form below gets prepopulated.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/new-delivery-columns.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Before the redesign, it was impossible to add a new delivery into a campaign unless you create the delivery from the campaign page. We changed that with the introduction of bookings, an entirely new entry point for creating ad deliveries.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/new-delivery-add-to-campaign.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Earlier, our customers weren’t able to change the spec, too. It wasn’t a big issue until we introduced bookings. We brought this in for the rare cases when we can’t match the spec given by the booking with the one in our database.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/new-delivery-change-spec.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Ads that have been delivered can be repeated. Customers could repeat these ads from the “My Deliveries” page, but now they can do this on the “New Ad Delivery” page. It comes in handy when their task flow starts from the “Bookings” page.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/new-delivery-bottom.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Campaign Tool</Text>
          <Text>The Specle campaign tool is a tailor-made solution that allows agencies to manage campaigns from start to finish — from media schedule, throughout production, and to delivery.</Text>
          <Text>Before the redesign, the campaign tool interface was cluttered, and the task flow of adding a new campaign was complicated. Customers couldn’t prioritise the most important campaigns or see their progress at a glance.</Text>
        </Box>

        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="In campaigns, we shifted away from a table view to a card view. That provided the campaigns with extra visual clues. Specifically, we introduced a status bar which shows a progress-like bar filled with colour stripes representing statuses of each ad insertion.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/campaigns-index.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Cards became draggable so that every agency user could arrange campaigns however works best for them. We added the action menu to let users quickly edit, duplicate, or archive campaigns. Archived campaigns got stashed under a separate tab.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/campaigns-index-drag.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="You can create new campaigns not only from the campaigns page but also while composing a new ad delivery. In fact, you can do this from any page on Specle using a shortcut in the top navigation. The modal window stays the same and respects the context.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/campaigns-new.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="The campaign page received a better visual hierarchy, too. Now the insertion’s status and actions are easily distinguishable; customers can sort insertions however they like. The page has also got a search bar — it’s faster than scrolling.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/campaigns-show.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Ad Deliveries</Text>
          <Text>
          Ad Deliveries is a place where all the ad deliveries are displayed, whether they are part of a campaign or not. You can think of it as a catalog of all ad materials you’ve sent with filtering options provided. It’s a good entry point for repeating a delivery or finding an old one to discuss it with a client.
          </Text>
        </Box>

        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Unlike before, now all tables are unified across the service: in rows, all actions are located on the right and available on hover, and status is always to the left of the action.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/ad-deliveries.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Before the redesign, you could filter the results but you couldn’t see what filters are applied (or are they) unless you open the filter modal window. Now we show all applied filters just below the filter/search bar.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/ad-deliveries-filtered.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Clicking the “Repeat” or “Replace” button shows a modal window with filters that customers can use to narrow down the results of repeatable deliveries.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/ad-deliveries-repeat.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Ad Spec Repository</Text>
          <Text>
          Initially, Specle was an ad spec repository of publishers and publications with their ad specifications. Through the years it became an ad delivery platform built on top of it. Each new customer stumbles on the repository first as it’s the only entry point for them to send a new ad.
          </Text>
        </Box>

        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Our spec repository received better navigation with publishers grouped by country as we aimed to expand in the markets abroad.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/publications.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Publisher page got refined branding features optimised for small screens.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/publisher.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Publication page was redesigned from scratch and received a sticky sidebar navigation to move around sections faster. We also added the functionality for sharing specs via an email or with a unique link.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/publication.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Settings</Text>
          <Text>Previously the Settings page was in chaos. Some of its child screens were completely disconnected from the parent and led to a dead end. There were no breadcrumbs to go back, or a settings sidebar wasn’t rendered.</Text>
          <Text>The reason for that mess was simple: under the hood, these child pages were managed by different Rails controllers. To fix this, we introduced a shared layout made specifically for the settings page.</Text>
        </Box>

        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="General settings screen.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/account-settings.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Team management screen.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/users.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Integrations screen.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/integrations.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Integration screen.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/integration.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Billing screen.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/billing.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4, mx: [-4, -6, -8]}}>
      <Toggle.Heading index="④" sx={{variant: 'text.h2', mx: [4,6,8], cursor: 'pointer', '&:hover': {opacity: 0.3}}}>Bookings</Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, mx: [4,6,8], mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>In 2019, we were forced to add integration with PADN, a third-party service providing copy chasing automation for the major UK publishers.</Text>
        </Box>
        <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', mx: [4,6,8], maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", rowGap: 0, columnGap: 8}}>
            <Text as="p">
              Due to the short deadline, we shipped the first release of Bookings as an isolated tool that allowed agencies to see the bookings and confirm or reject them. The designed flow was meant to allow users to go to the send ad form — pre-populated with all the booking details — off the bat.
            </Text>
            <Text as="p">
              But with the PADN API connected, it turned out that we weren’t able to match every fifth spec provided in the booking to our database. As a hotfix, we had to add an intermediate page to the flow, on which the user could hand-pick the correct spec. Later, we got rid of that page and added the spec selection into the “New ad delivery” form.
            </Text>
          </Grid>
        </Box>

        <Slideshow sx={{mb:8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Bookings is a place where agencies can see all the booked ads as soon as the editorial team at publisher’s end has set them. All bookings are provided with the ad spec and a deadline date. This dashboard, combined with the copy submission deadlines reminders, ensures delivery of ad copy in time.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/bookings-index.png"  alt='' height="40vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="For better readability, all tables on Specle, including Bookings, got a sticky header.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/bookings-scrolled-down.png"  alt='' height="40vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="While working on Bookings, we also introduced bulk actions which later became available in other tables: in Ad Deliveries and within Campaigns, with their own set of action.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/bookings-bulk-actions.png"  alt='' height="40vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="With the booking confirmation, we show customers a snack bar to perform the next action: send an ad or add to a campaign. They don’t usually go further right off the bat, so we decided to use a snack bar that doesn’t distract them from confirming other bookings and disappears in 4 seconds.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/bookings-snack.png"  alt='' height="40vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="If the customer chooses to add booking into a campaign, we show a modal window with their active campaigns. There is also an option to create a new one so that they don’t need to worry about creating it in advance.">
            <Box sx={{variant: "boxes.slide"}}>
            <Image src="specle/bookings-add-to-campaign.png"  alt='' height="40vw" />
            </Box>
          </Box>
        </Slideshow>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="⑤" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Internal Tools
      </Toggle.Heading>
      <Toggle.Content sx={{mx: [-4, -6, -8]}}>
        <Box sx={{mt: 4, mx: [4,6,8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            We’ve introduced a couple of new internal tools for our customer operations teams to increase their productivity.
          </Text>
        </Box>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Pro Jobs Dashboard</Text>
          <Text>
            Specle Pro, one of the Specle services, provides functionality for fixing ad materials with the help of our prepress technicians. For customers it has always been a frictionless experience but “behind the scenes” it was daunting.</Text>
          <Text>
            Our specialists are notified about new Pro jobs via e-mail. Before the dashboard was released, they had to forward these email to others telling them that the task is to be performed. Only after that, they were finally able to actually work on it.
          </Text>
        </Box>
        <Box sx={{mb: 8, mx: [4,6,8]}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: 7, mt: 7, mb: 4}}>
            <Image src="specle/pro-jobs.png" alt='New Navigation' height="50vw" sx={{mx: "auto"}}/>
          </Box>
          <Text variant="caption" as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          I’ve designed a new dashboard for prepress technicians. It displays the unfinished Pro jobs (both assigned and unassigned) and lets them pick one into work. They can see the required spec at a glance, download the original artwork and upload the fixed one in a click.
          </Text>
        </Box>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>Manual Deliveries</Text>
          <Text>
            Usually, we send ad materials from our clients to publishers automatically. However, a few regional newspapers and magazines neither use Specle for Publishers, nor provide an API to receive ads on their end. Delivering ads to such publishers is laborious.
          </Text>
          <Text>
            Our customer support team used to get emails with new delivery jobs and filter those that go to such publishers (kudos to rules in Gmail.) Then, to upload the ad, they had to sign in to the publisher’s ad portal or connect to its FTP server.
          </Text>
        </Box>

        <Slideshow sx={{mb: 8}} altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="To fix this flow we created a feed of manual jobs that not only saved support staff the trouble of digging through lots of emails but also addressed the security concerns">
            <Box sx={{variant: "boxes.slide"}}>
              <Image src="specle/manual-jobs.png"  alt='' height="50vw" />
            </Box>
          </Box>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Now, when a support employee opens a job, they immediately gets to a new tab with the publisher’s ad portal opened in iframe. This allows them to copy the credentials to log in, download the automatically amended (or original) ad to upload, see its specs, and mark the job as completed — all in a single page.">
            <Box sx={{variant: "boxes.slide"}}>
              <Image src="specle/manual-jobs-iframe.png"  alt='' height="50vw" />
            </Box>
          </Box>
        </Slideshow>
      </Toggle.Content>
    </Toggle>

    <Toggle on sx={{my: 4}}>
      <Toggle.Heading index="⑥" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Results
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>First, we’ve started to ship solutions, not features. The development team has always valued design and user experience, so when I joined them, we all worked with dedication and effort to improve the product.</Text>
          <Box as="ul" sx={{variant: 'styles.ul', mt: 0, maxWidth: "700px"}}>
            <Styled.li>We've shipped most of the proposed task flows. They were successfully validated by customers.</Styled.li>
            <Styled.li>We've made interface copy short and succinct.</Styled.li>
            <Styled.li>We've created a set of internal tools to lower the workload on the support.</Styled.li>
            <Styled.li>We introduced the PADN integration that streamlined delivering ad materials to the publishers.</Styled.li>
            <Styled.li>I created a CSS framework with the HTML snippets for creating design components, and guidelines to use them. Now the developers can use it to speed up the development process.</Styled.li>
          </Box>
        </Box>
      </Toggle.Content>
    </Toggle>
  </Layout>